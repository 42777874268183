import React, {
  cloneElement,
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import clsx from "clsx";
import _debounce from "lodash/debounce";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";

import styles from "./index.module.css";

const scrollAmount = 10;

type FadeScrollerProps = { children: ReactElement & { ref?: Ref<any> }; className?: string };

const FadeScroller = forwardRef(({ className, children }: FadeScrollerProps, ref: ForwardedRef<HTMLDivElement>) => {
  const [visibleButtons, setVisibleButtons] = useState({ previous: false, next: false });
  const containerRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement | null>;
  const innerRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement | null>;
  const [scroll, setScroll] = useState<string | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout>(null) as MutableRefObject<NodeJS.Timeout>;
  const previousButton = useRef<HTMLButtonElement>(null);
  const nextButton = useRef<HTMLButtonElement>(null);
  const [isTouching, setIsTouching] = useState(false);
  const preventContextMenu = scroll || isTouching;

  const checkIfScrolledToEnd = () => {
    if (!containerRef.current || !innerRef.current) {
      return;
    }

    const containerEnd = containerRef.current.scrollLeft + containerRef.current.offsetWidth;

    return Math.round(containerEnd) === innerRef.current.offsetWidth;
  };

  const updateButtons = useCallback(() => {
    if (!containerRef.current || !innerRef.current) {
      return;
    }

    setVisibleButtons({ previous: containerRef.current.scrollLeft > 0, next: !checkIfScrolledToEnd() });
  }, []);

  const goLeft = (isTouch?: boolean) => {
    if (!scroll) {
      if (isTouch) {
        setIsTouching(true);
      }

      setScroll("left");
    }
  };

  const goRight = (isTouch?: boolean) => {
    if (!scroll) {
      if (isTouch) {
        setIsTouching(true);
      }

      setScroll("right");
    }
  };

  const stopScroll = useCallback((isEnd?: boolean) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (isEnd) {
      setIsTouching(false);
    }

    setScroll(null);
  }, []);

  const handleScroll = useCallback(() => {
    if (!containerRef.current || !scroll) {
      return;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (scroll === "left") {
      if (containerRef.current.scrollLeft === 0) {
        stopScroll();
        return;
      }

      containerRef.current.scrollLeft -= scrollAmount;
    } else {
      if (innerRef.current && checkIfScrolledToEnd()) {
        stopScroll();
        return;
      }

      containerRef.current.scrollLeft += scrollAmount;
    }

    timeoutRef.current = setTimeout(handleScroll, 10);
  }, [scroll, stopScroll]);

  const debouncedUpdateButtons = useMemo(() => _debounce(updateButtons, 500), [updateButtons]);

  const handleResize = useCallback(() => {
    debouncedUpdateButtons();
  }, [debouncedUpdateButtons]);

  useEffect(() => {
    if (!containerRef.current || !innerRef.current) {
      return;
    }

    if (innerRef.current.offsetWidth > containerRef.current.offsetWidth) {
      updateButtons();
    }
  }, [updateButtons]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (scroll) {
      handleScroll();
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [handleScroll, scroll]);

  return (
    <div
      className={clsx(className, "overflow-hidden w-full relative", preventContextMenu && "select-none")}
      onContextMenu={e => {
        if (preventContextMenu) {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }
      }}
      ref={ref}
    >
      <div className={clsx(styles.scrollButtonLeft, visibleButtons.previous && styles.active)}>
        <button
          onMouseDown={() => goLeft()}
          onMouseMove={() => stopScroll()}
          onMouseUp={() => stopScroll(true)}
          onTouchStart={() => goLeft(true)}
          onTouchMove={() => stopScroll()}
          onTouchEnd={e => {
            e.preventDefault();
            stopScroll(true);
          }}
          className={clsx("p-0", preventContextMenu && "pointer-events-none")}
          ref={previousButton}
          aria-label="Scroll left"
        >
          <ChevronLeftIcon className="h-6 w-6 md:h-8 md:w-8" />
        </button>
      </div>
      <div className="-m-2">
        <div className={styles.scrollContainer} ref={containerRef} onScroll={updateButtons}>
          {cloneElement(children, {
            ref: node => {
              innerRef.current = node;

              if ("ref" in children && children.ref) {
                const childRef = children.ref;

                if (typeof childRef === "object" && childRef.hasOwnProperty("current")) {
                  (childRef as MutableRefObject<any>).current = node;
                } else if (typeof childRef === "function") {
                  childRef(node);
                }
              }
            },
          })}
        </div>
      </div>
      <div className={clsx(styles.scrollButtonRight, visibleButtons.next && styles.active)}>
        <button
          onMouseDown={() => goRight()}
          onMouseMove={() => stopScroll()}
          onClick={() => stopScroll(true)}
          onMouseUp={() => stopScroll(true)}
          onTouchStart={() => goRight(true)}
          onTouchMove={() => stopScroll()}
          onTouchEnd={e => {
            e.preventDefault();
            stopScroll(true);
          }}
          className={clsx("p-0", preventContextMenu && "pointer-events-none")}
          ref={nextButton}
          aria-label="Scroll right"
        >
          <ChevronRightIcon className="h-6 w-6 md:h-8 md:w-8" />
        </button>
      </div>
    </div>
  );
});
FadeScroller.displayName = "FadeScroller";

export default FadeScroller;
