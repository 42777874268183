import React, { MutableRefObject, useCallback, useEffect, useRef } from "react";

import clsx from "clsx";
import AnimatedCheck, { CheckVariants } from "components/animated-check";
import FadeScroller from "components/fade-scroller";
import Heading from "components/heading";
import HeroSection from "components/hero-section";
import _debounce from "lodash/debounce";
import { useInView } from "react-intersection-observer";

import styles from "./index.module.css";

const items = [
  {
    title: "Integrations",
    description: "Bring your favorite tools; we’ll connect them all.",
  },
  {
    title: "Sales",
    description: "Streamline your sales process and close more deals.",
  },
  {
    title: "Financing",
    description: "Simplify financing with integrated solar lending.",
  },
  {
    title: "Proposals",
    description: "Impress your customers with stunning proposals.",
  },
  {
    title: "Doc Signing",
    description: "Take the pain out of solar docs with Docflo.",
  },
  {
    title: "Projects",
    description: "Deliver projects on track and on time, every time.",
  },
  {
    title: "Support",
    description: "Delight your customers with a centralized portal.",
  },
];

const title = "Streamline the Solar Process";

const Streamline = () => {
  const [inViewRef, inView] = useInView({ threshold: 0.4, triggerOnce: true });
  const innerRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement | null>;
  const timelineRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement | null>;
  const widthRef = useRef(0);

  const adjustTimelineMask = useCallback(() => {
    const masks = document.querySelectorAll(".timeline-mask");
    const checks = document.querySelectorAll(".animated-check");

    if (!masks.length || !timelineRef.current || !innerRef.current || widthRef.current === window.innerWidth) {
      return;
    }

    widthRef.current = window.innerWidth;

    const firstCheckRect = checks[0].getBoundingClientRect();
    const lastCheckRect = checks[checks.length - 1].getBoundingClientRect();

    // Update the timeline styles
    timelineRef.current.style.height = `${Math.round(firstCheckRect.height + 2)}px`;
    timelineRef.current.style.width = `${Math.round(lastCheckRect.right - firstCheckRect.left + 2)}px`;
    timelineRef.current.style.left = `${Math.round(
      firstCheckRect.left - innerRef.current.getBoundingClientRect().left - 1
    )}px`;

    // Update the width for each mask to create the line between each timeline item
    for (let i = 0; i < masks.length; i++) {
      const mask = masks[i] as HTMLDivElement;
      const leftCheckRect = (checks[i] as HTMLDivElement).getBoundingClientRect();
      const rightCheckRect = (checks[i + 1] as HTMLDivElement).getBoundingClientRect();

      mask.style.width = `${Math.round(rightCheckRect.left - leftCheckRect.right - 4)}px`;
      mask.style.border = `${Math.round((leftCheckRect.height + 8) / 2)}px solid white`;
    }
  }, []);

  const handleResize = useCallback(() => {
    adjustTimelineMask();
  }, [adjustTimelineMask]);

  useEffect(() => {
    adjustTimelineMask();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize, adjustTimelineMask]);

  return (
    <div className="shadow-lg relative z-[1] bg-gradient-to-b from-gray-100 via-white to-white lg:bg-none">
      <HeroSection title={title}>
        <div className="w-full">
          <HeroSection.Content>
            <HeroSection.Header>
              <Heading tag="h2">{title}</Heading>
            </HeroSection.Header>
            <p className="text-lg text-gray-500 mx-auto md:w-3/4 lg:w-2/3 md:text-center">
              Enerflo creates efficiencies from lead to PTO to grow your solar business. As a true open platform, we
              invite you to bring your favorite tools and let Enerflo connect them all together. Trust your tech with
              us, and we’ll blow your mind.
            </p>
          </HeroSection.Content>

          <FadeScroller className="mt-10" ref={inViewRef}>
            <div className="inline-flex relative px-4" ref={innerRef}>
              <div className="absolute top-[25px] left-0 h-[42px] rounded-full w-full z-0 mx-auto" ref={timelineRef}>
                <div className={clsx(styles.timeline, inView && styles.animate)} />
              </div>
              {items.map((item, index) => (
                <div key={item.title} className={styles.timelineItem}>
                  <div className={clsx(styles.timelineCheckContainer)}>
                    {index !== items.length - 1 && <div className={clsx(styles.timelineMask, "timeline-mask")} />}
                    <AnimatedCheck
                      className="bg-transparent animated-check"
                      iconClassName="h-6 w-6 md:h-8 md:w-8"
                      delay={index}
                      animate={inView}
                      variant={CheckVariants.TRANSPARENT}
                    />
                  </div>
                  <Heading tag="h6" className="mt-3">
                    {item.title}
                  </Heading>
                  <p className="mt-2 text-gray-500 text-sm">{item.description}</p>
                </div>
              ))}
            </div>
          </FadeScroller>
        </div>
      </HeroSection>
    </div>
  );
};

export default Streamline;
